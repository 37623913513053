import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import queryString from 'query-string';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CLinkCard,
  AssetImage,
  CHeroImg,
  CDefinition,
  CBtnList,
  CSliderMedia,
} from '../../../components/_index';
import shopGet from '../../../utils/shop-get';
import menuChoice from '../../../utils/menu-choice';
import menuGet from '../../../utils/menu-get';

// markup
const SubPage = ({ data, location }: any) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const { contentId, draftKey } = queryString.parse(location.search);
  const [shopdata, setShopData] = useState<any | null>(shopGet('fontaine'));
  if (contentId) {
    useEffect(() => {
      fetch(
        `https://${process.env.MICRO_CMS_SERVICE_DOMAIN}.microcms.io/api/v1/shop_srph/${contentId}?draftKey=${draftKey}`,
        {
          headers: {
            'X-MICROCMS-API-KEY': process.env.MICRO_CMS_API_KEY || '',
          },
        }
      )
        .then((res) => res.json())
        .then((res) => setShopData(res));
    }, []);
  }

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          logo: {
            src: '/assets/images/restaurants/fontaine/logo.svg',
            alt: 'フォンテーヌ',
          },
          title: {
            ja: 'フォンテーヌ',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/fontaine/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/fontaine/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'レストラン',
                path: '/restaurants/',
              },
            ],
            current: {
              label: 'フォンテーヌ',
            },
          }}
        />
      </CJumbotron>
      <div className="l_sect">
        <section className="u_mbExLarge">
          <LWrap>
            <h2 className="c_headingLv2">
              リゾートステイを優雅にたのしむ
              <br />
              癒やされるロビーラウンジ
            </h2>
            <p className="c_sectLead">
              ホテルライフをアップデートする素敵なロビーラウンジ。
              <br />
              ティータイムでは季節のアフタヌーンティーやロールケーキをご用意。
              <br />
              バータイムではライトアップされたガーデンを眺めながらお酒を嗜む優雅なひとときをお過ごしください。
            </p>
            <CHeroImg
              img={{
                src: '/assets/images/restaurants/fontaine/img_hero.png',
                alt: '',
              }}
            />
          </LWrap>

          {/* LIMITED MENU - 期間限定メニュー */}
          <LWrap size="small" exClass="u_mtExLarge">
            <CSectTitle
              title={{
                ja: <>期間限定メニュー</>,
                en: <>LIMITED MENU</>,
              }}
            />
            <CHeroImg
              img={{
                src: '/assets/images/restaurants/fontaine/img_fontaine_limited.png',
                alt: '',
              }}
              imgSp={{
                src: '/assets/images/restaurants/fontaine/img_fontaine_limited__sp.png',
                alt: '',
              }}
            />
            <h3 className="c_headingLv3 u_tac">
            多彩なアイテムを味わえるアフタヌーンティーセット
            </h3>
            <p className="c_sectLead">
            定番の苺のショートケーキをはじめ、濃厚なマスカルポーネとコーヒークリームのティラミスパイ、
            <br className="u_pc" />
            抹茶のオペラやピーチアーモンドタルトなど多彩なアイテムをご堪能いただけるラインナップです。
            <br className="u_pc" />
            干し柿のマカロンやマロンスフレで秋のエッセンスもお楽しみいただけます。
            <br className="u_pc" />
            午後のおいしいひとときをごゆっくりお過ごしください。
            </p>
            <CBtnList
              data={[
                {
                  label: 'メニューはこちら',
                  link: {
                    href: '/assets/files/pdf/fon_20241101_afternoonteaonly.pdf',
                    blank: false,
                  },
                  color: 'borderTheme',
                  icon: 'blank',
                },
              ]}
            />
          </LWrap>
          {/* LIMITED MENU - 期間限定メニュー */}

          <LWrap>
            <CSliderMedia
              data={[
                {
                  img: [
                    {
                      img: {
                        src: '/assets/images/restaurants/fontaine/img_fontaine02.png',
                        alt: '',
                      },
                    },
                  ],
                  title: (
                    <>
                      午後のひとときを満喫
                      <br />
                      日々の喧騒を忘れるティータイム
                    </>
                  ),
                  text: (
                    <>
                      豊かな自然に囲まれた閑静な
                      <br className="u_pc" />
                      リゾート環境ならではの贅沢なティータイム。
                      <br className="u_pc" />
                      美しい景色を眺めながらの美味しいお茶とスイーツは格別。
                      <br className="u_pc" />
                      日々の疲れをリフレッシュしてくれる、
                      <br className="u_pc" />
                      ここにしかない自分時間をお過ごしください。
                    </>
                  ),
                },
                {
                  img: [
                    {
                      img: {
                        src: '/assets/images/restaurants/fontaine/img_fontaine.png',
                        alt: '',
                      },
                    },
                  ],
                  title: (
                    <>
                      くつろぎのひとときを満喫
                      <br />
                      ゆったり過ごせる空間
                    </>
                  ),
                  text: (
                    <>
                      四季の移り変わりが美しいガーデンを眺めながら
                      <br className="u_pc" />
                      ゆったりとしたお席でくつろぐ優雅な時間。
                      <br className="u_pc" />
                      来るたびに変化する景色とともに、
                      <br className="u_pc" />
                      非日常を感じる特別なひとときをお過ごしください。
                    </>
                  ),
                },
                {
                  img: [
                    {
                      img: {
                        src: '/assets/images/restaurants/fontaine/img_fontaine03.png',
                        alt: '',
                      },
                    },
                  ],
                  title: (
                    <>
                      自宅でちょっと贅沢
                      <br />
                      お土産にも喜ばれるテイクアウト
                    </>
                  ),
                  text: (
                    <>
                      フォンテーヌで満喫したティータイムのお土産に
                      <br className="u_pc" />
                      ホテルセレクションのロールケーキとクッキーをテイクアウト。
                      <br className="u_pc" />
                      家族や友人と過ごすおうち時間のお供に
                      <br className="u_pc" />
                      美味しいスイーツをおたのしみください。
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>
        <section className="l_sect u_bgGray u_mbExLarge">
        <LWrap>
          <CSectTitle
            title={{
              ja: <>メニュー</>,
              en: <>MENU</>,
            }}
          />
          <CLinkCard
            align="center"
            data={menuChoice(menuGet(), ['フォンテーヌ 店舗 トップ'], 30)}
          />
          <div className='c_btnListCol03'>
            <CBtnList
              align='left'
              data={[
                {
                  label: 'ご予約',
                  link: {
                    href: 'https://booking.ebica.jp/webrsv/search/e014024501/22525',
                    blank: true,
                  },
                  icon: 'blank',
                },
                {
                  label: 'ご予約（テイクアウト）',
                  link: {
                    href: 'https://booking.ebica.jp/webrsv/plan_search/e014024501/22525',
                    blank: true,
                  },
                  icon: 'blank',
                },
              ]}
            />
          </div>
          <ul className="c_noteList u_mtMedium">
            <li>掲載の内容は予告なく変更する場合があります。</li>
            <li>画像はイメージです。</li>
            <li>表示価格は消費税、サービス料込みの価格です。</li>
            <li>
              メニュー内容は仕入れの状況によって変更になる場合がございます。
            </li>
          </ul>
        </LWrap>
        </section>
        <section className="u_mbExLarge">
          <LWrap>
            <CSectTitle
              title={{ ja: <>営業時間｜ご利用案内</>, en: <>GUIDE</> }}
            />
            <CDefinition
              exClass="u_mb100 u_mb80_sp"
              data={shopdata.information.map((item: any) => {
                return {
                  title:
                    item.keyname &&
                    item.keyname.split('\n').map((t: string) => (
                      <>
                        {t}
                        <br />
                      </>
                    )),
                  text: (
                    <div
                      className="wysiwyg"
                      dangerouslySetInnerHTML={{
                        __html: `${item.value}`,
                      }}
                    />
                  ),
                };
              })}
            />
          </LWrap>
        </section>
      </div>
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
